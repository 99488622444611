import { useEffect, useRef } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { v1 as uuidv1 } from 'uuid'
import classNames from 'classnames'
import { useSlice } from 'State'
import useTimers from 'Hooks/useTimers'
import useIsLoaded from 'Hooks/useIsLoaded'
import TransitionWrapper from 'Components/TransitionWrapper'
import CaretRightIcon from 'Images/icons/caret-right.svg'
import AdvancedIcon from 'Images/icons/advanced.svg'
import BasicIcon from 'Images/icons/source-outline.svg'
import PlusIcon from 'Images/icons/plus.svg'
import styles from 'Pages/AddFeed.module.scss'

export default function AddFeedSource() {
  const appState = useSlice('authorizedAccounts')
  const { sourceType } = useParams()
  const { st } = useTimers()
  const navigate = useNavigate()
  const [isLoaded, setIsLoaded] = useIsLoaded(true)
  const animateSourcesRef = useRef(false)
  const sourcesContainerRef = useRef(null)

  function animove(url) {
    setIsLoaded(false)
    st(() => {
      navigate(url)
    }, 200)
  }

  useEffect(() => {
    st(() => {
      animateSourcesRef.current = true
    }, 1000)
  }, [])

  const igAccounts = Object.values(appState.authorizedAccounts)
    .filter((account) => {
      return sourceType === 'hashtag' && account.accountType !== 'FACEBOOK'
        ? false
        : true
    })
    .sort((a, b) => {
      if (a.proInstagramAccounts === 'LOADING') return 1
      if (b.proInstagramAccounts === 'LOADING') return -1
      return 0
    })
    .reduce((acc, curr) => {
      if (curr.accountType === 'FACEBOOK') {
        if (
          curr.proInstagramAccounts === 'LOADING' ||
          !curr.facebookUserId ||
          !curr.longLivedAccessToken
        ) {
          acc[curr.facebookUserId] = {
            loading: true,
            id: curr.facebookUserId || uuidv1(),
          }
        } else {
          Object.values(curr.proInstagramAccounts).forEach((proAccount) => {
            acc[proAccount.instagramUserId + '_pro'] = {
              ...proAccount,
              api: 'GRAPH',
              parentFacebookUserId: curr.facebookUserId,
            }
          })
        }
        return acc
      } else {
        return { ...acc, [curr.instagramUserId]: curr }
      }
    }, {})

  const sourceEls = Object.values(igAccounts).map((account, i) => {
    return account.loading ? (
      <div className={styles.source_loading} key={account.id}>
        <div className={styles.source_loading__loader}>
          <l-zoomies
            size={80}
            color="var(--color-text-green)"
            speed={1.3}
          ></l-zoomies>
        </div>
        Processing new sources.
        <br />
        This shouldn&apos;t take long.
      </div>
    ) : (
      <TransitionWrapper
        key={account.instagramUserId + account.api}
        scale={animateSourcesRef.current}
        className={styles.source_button__container}
      >
        <button
          id={i === 0 ? 'skip-target' : ''}
          aria-label={`Advanced Instagram source: ${account.username}`}
          className={styles.source_button}
          onClick={() =>
            animove(
              `${account.parentFacebookUserId || ''}_${
                account.instagramUserId
              }/type`,
            )
          }
        >
          <div className={styles.source_button__type}>
            {account.api === 'GRAPH' ? <AdvancedIcon /> : <BasicIcon />}
          </div>
          <div className={styles.source_button__text}>
            <span className={styles.source_button__label}>
              {account.api === 'GRAPH' ? 'Advanced' : 'Basic'}
            </span>
            @{account.username}{' '}
          </div>
          <CaretRightIcon className={styles.source_button__caret} />
        </button>
      </TransitionWrapper>
    )
  })

  const sourcesHaveOverflow = sourcesContainerRef.current
    ? sourcesContainerRef.current.offsetHeight <
      sourcesContainerRef.current.scrollHeight
    : false

  return (
    <>
      <h1 className={classNames(styles.title, { [styles.loaded]: isLoaded })}>
        Pick a source for your feed
      </h1>
      {sourceType === 'hashtag' && (
        <div
          className={classNames(styles.subtitle, {
            [styles.loaded]: isLoaded,
          })}
        >
          Hashtag feeds require an
          <br />
          advanced Instagram source
        </div>
      )}
      <div
        className={classNames(styles.sources_container, {
          [styles.has_overflow]: sourcesHaveOverflow,
        })}
      >
        <div
          ref={sourcesContainerRef}
          className={classNames(styles.sources, {
            [styles.loaded]: isLoaded,
          })}
        >
          {sourceEls}
          <Link
            to={
              sourceType === 'hashtag'
                ? '/add-source/advanced-instagram '
                : '/add-source/basic-instagram'
            }
            state={{
              afterAuthRedirect:
                sourceType === 'hashtag'
                  ? '/add-feed/hashtag/source'
                  : '/add-feed/user/source',
            }}
            className={classNames(styles.new_source_button)}
          >
            <div className={styles.new_source_button__inner}>
              <PlusIcon />
              New {sourceType === 'hashtag' ? 'advanced ' : ''}source
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}
