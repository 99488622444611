import { useSlice } from 'State'

export default function useFeatures() {
  const { user, plans } = useSlice('user', 'plans')
  let featuresArray = []

  if (user && plans) {
    const userPlan = user.plan || 'free'
    const planFeatures = plans[userPlan]?.features ?? []
    const userFeatures = user.planOverrides?.features ?? []
    featuresArray = [...planFeatures, ...userFeatures]
  }

  return {
    has: (...args) => args.every((arg) => featuresArray.includes(arg)),
    lacks: (...args) => args.some((arg) => !featuresArray.includes(arg)),
    list: featuresArray,
  }
}
