import { getFunctions, httpsCallable } from 'firebase/functions'
import blobToDataUrl from 'Helpers/blobToDataUrl'
const functions = getFunctions()

export async function warmupManageImageUploads() {
  try {
    await httpsCallable(
      functions,
      'manageImageUploads',
    )({
      warmup: true,
    })
  } catch (error) {
    console.log('failed to warmup function')
  }
}

/**
 *   @param {{image: Blob, uploadPath: string, bucket: string, maxWidth?: number, maxHeight?: number, quality?: number}} upload
 */
export async function uploadImage({
  image,
  path,
  bucket,
  maxWidth,
  maxHeight,
  quality,
  dbPath = '',
  dbField = null,
}) {
  if (!(image instanceof Blob)) {
    throw new Error('Missing image')
  }
  const dataUrl = await blobToDataUrl(image)
  return httpsCallable(
    functions,
    'manageImageUploads',
  )({
    action: 'upload',
    base64Source: dataUrl,
    path,
    bucket,
    maxHeight,
    maxWidth,
    quality,
    dbPath,
    dbField,
  })
}

/**
 *   @param {{imagePaths: string[], bucket: string, dbPath?: string, dbField?: string}} upload
 */
export async function deleteImages({
  imagePaths,
  bucket,
  dbPath = '',
  dbField = null,
}) {
  return httpsCallable(
    functions,
    'manageImageUploads',
  )({
    action: 'delete',
    imagePaths,
    bucket,
    dbPath,
    dbField,
  })
}
