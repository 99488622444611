import React, { useState, useEffect } from 'react'
import { Link, useMatch, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import md5 from 'md5'
import classNames from 'classnames'
import { signOut } from 'firebase/auth'
import { auth } from 'Fire/firebaseInit'
import { useSlice } from 'State'
import useWindowSize from 'Hooks/useWindowSize'
import Usage from 'Components/Usage'
import Logo from 'Images/mark.svg'
import IconFeed from 'Images/icons/feed-outline.svg'
import IconSource from 'Images/icons/source-outline.svg'
import IconSupport from 'Images/icons/support-outline.svg'
import IconUpgrade from 'Images/icons/upgrade.svg'
import styles from './MobileHeader.module.scss'

export default function MobileHeader({ show = true }) {
  const windowSize = useWindowSize()
  const location = useLocation()
  const { user } = useSlice('user')
  const [photoIsLoaded, setPhotoIsLoaded] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const providerData = auth?.currentUser?.providerData
  const userPhoto = providerData.reduce((acc, curr) => {
    if (curr.userPhoto?.length > 0) {
      return curr.userPhoto
    }
    return acc
  }, '')
  const photoSrc =
    userPhoto ||
    `https://www.gravatar.com/avatar/${md5(
      user.email.toLowerCase(),
    )}.jpg?d=blank`

  let initials = ''
  const userName = providerData.reduce((acc, curr) => {
    if (curr.displayName?.length > 0) {
      return curr.displayName
    }
    return acc
  }, '')
  if (userName) {
    const namArr = userName.split(' ')
    const firstInitial = namArr.length > 0 ? namArr[0][0] : ''
    const secondInitial = namArr.length > 1 ? namArr[1][1] : ''
    initials = firstInitial + secondInitial
  } else {
    initials = user.email[0]
  }

  function handlePhotoLoad() {
    setPhotoIsLoaded(true)
  }

  function toggleMenu() {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    setIsOpen(false)
  }, [location])

  const toggleClass = classNames([styles.menu_toggle], {
    [styles.menu_toggle_open]: isOpen,
  })

  return windowSize.width <= 600 && show ? (
    <div className={classNames(styles.container, { [styles.open]: isOpen })}>
      <div className={styles.inner}>
        <Link to="/feeds" className={styles.logo} tabIndex={-1}>
          <Logo />
        </Link>
        <button
          className={toggleClass}
          onTouchStart={toggleMenu}
          onTouchEnd={(e) => e.preventDefault()}
          onClick={toggleMenu}
          aria-label="Menu Toggle"
        >
          <div className={styles.menu_icon} />
        </button>
        <div className={styles.menu}>
          <div className={styles.account}>
            <div className={styles.avatar}>
              <img
                className={classNames(styles.photo, {
                  [styles.loaded]: photoIsLoaded,
                })}
                src={photoSrc}
                onLoad={handlePhotoLoad}
              />
              {initials}
            </div>
            <div className={styles.account__email}>{user.email}</div>
          </div>
          <Usage />
          <nav className={styles.nav}>
            <SmartLink to="/feeds" include="" className={styles.nav__item}>
              <IconFeed />
              Feeds
            </SmartLink>
            <SmartLink to="/sources" className={styles.nav__item}>
              <IconSource /> Sources
            </SmartLink>
            <SmartLink to="/help" className={styles.nav__item}>
              <IconSupport /> Help
            </SmartLink>

            <div className={styles.account_nav}>
              {user.plan !== 'Enterprise' && (
                <SmartLink to="/choose-plan" className={styles.nav__item}>
                  <IconUpgrade /> Upgrade
                </SmartLink>
              )}
              <SmartLink to="/account" className={styles.nav__item}>
                Account
              </SmartLink>
              <button
                className={styles.nav__item}
                to="/help"
                onClick={() => signOut(auth)}
                size="medium"
              >
                Sign Out
              </button>
            </div>
          </nav>
        </div>
        <div
          onClick={toggleMenu}
          className={classNames(styles.mask, { [styles.open]: isOpen })}
        />
      </div>
    </div>
  ) : null
}

function SmartLink({
  loose,
  to,
  className,
  currentClass = styles.current,
  include = 'none',
  children,
  tabIndex,
}) {
  const matchPattern = loose ? to + '/*' : to
  const match = useMatch(matchPattern)
  const includeMatch = useMatch(include)

  return (
    <Link
      to={to}
      className={classNames(styles.link, className, {
        [currentClass]: !!match || !!includeMatch,
      })}
      tabIndex={tabIndex}
    >
      {children}
    </Link>
  )
}

SmartLink.propTypes = {
  loose: PropTypes.bool,
  to: PropTypes.string,
  className: PropTypes.string,
  currentClass: PropTypes.string,
  include: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
}
