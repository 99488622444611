import { getFunctions, httpsCallable } from 'firebase/functions'

const functions = getFunctions()

/*
 *   Create a setup intent
 */
export function updateUserEmail(newEmail, getIdFromEmail = false) {
  return httpsCallable(
    functions,
    'updateUserEmail',
  )({
    email: newEmail,
    getIdFromEmail,
  })
}
