import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSlice } from 'State'
import useAuthUrl from 'Hooks/useAuthUrl'
import Popup from 'Components/Popup'
import CopyText from 'Components/CopyText'
import CaretRightIcon from 'Images/icons/caret-right.svg'
import styles from './ReauthPopup.module.scss'

export default function ReauthPopup({
  sourceId,
  onRequestClose,
  closeFocusRef,
  redirectTo = '/feeds',
}) {
  const { authorizedAccounts } = useSlice('authorizedAccounts')
  const source = authorizedAccounts[sourceId]
  const { basicAuthUrl, graphAuthUrl } = useAuthUrl({
    type: 'basic',
    afterAuthRedirect: redirectTo,
    isReauth: true,
  })

  const authLink =
    source.accountType === 'FACEBOOK' ? graphAuthUrl : basicAuthUrl

  return (
    <Popup onRequestClose={onRequestClose} closeFocusRef={closeFocusRef}>
      <div className={classnames(styles.container)}>
        <header className={styles.header}>
          <h1 className={styles.title}>
            Reauthorize {source.username && '@'}
            {source.username || source.name}
          </h1>
        </header>
        <div className={styles.content}>
          <div className={styles.intro}>
            Visit the link below while logged into{' '}
            {source.accountType === 'FACEBOOK' ? 'Facebook' : 'Instagram'}.
          </div>
          <div className={styles.auth_link}>
            <CopyText className={styles.copy_text} value={authLink} />
            <a href={authLink} className={styles.button}>
              Open link <CaretRightIcon />
            </a>
          </div>

          <div className={styles.hint}>
            <b>Tip:</b> You can send this link to other people. They don&apos;t
            need to be logged-in to Behold to use it.
          </div>
        </div>
      </div>
    </Popup>
  )
}

ReauthPopup.propTypes = {
  onRequestClose: PropTypes.func,
  closeFocusRef: PropTypes.object,
}
