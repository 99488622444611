import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { auth } from 'Fire/firebaseInit'
import { reauthenticate } from 'Fire/firebaseActions'
import {
  unlink,
  linkWithPopup,
  linkWithCredential,
  reauthenticateWithPopup,
  updateEmail,
  EmailAuthProvider,
  GoogleAuthProvider,
  GithubAuthProvider,
} from 'firebase/auth'
import { useSlice } from 'State'
import { validateEmail } from 'Helpers/utils'
import { updateUserEmail } from 'Helpers/updateUser'
import { sendVerificationLink } from 'Helpers/sendVerificationLink'
import Popup from 'Components/Popup'
import HelpTooltip from './HelpTooltip'
import ControlledInput from 'Components/ControlledInput'
import { Form, FormItem } from 'Components/Form'
import GoogleLogo from 'Images/google.svg'
import GithubLogo from 'Images/github.svg'
import EmailIcon from 'Images/icons/email-login.svg'
import TrashIcon from 'Images/icons/trash.svg'
import EditIcon from 'Images/icons/edit.svg'
import PlusIcon from 'Images/icons/plus.svg'
import MinusIcon from 'Images/icons/minus.svg'
import styles from './ManageLoginsPopup.module.scss'

export default function ChangeBillingEmailPopup({
  onRequestClose,
  closeFocusRef,
}) {
  const appState = useSlice('user', 'addNotification', 'updateUser')
  const [forceClose, setForceClose] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showNewEmailLoginForm, setShowNewEmailLoginForm] = useState(false)
  const [showEditEmailForm, setShowEditEmailForm] = useState(false)
  const [newAccountEmail, setNewAccountEmail] = useState('')
  const [newAccountPassword, setNewAccountPassword] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')

  const focusOnMount = useCallback((inputEl) => {
    if (inputEl) {
      inputEl.focus()
    }
  }, [])

  const loginProviders = auth?.currentUser?.providerData || []
  const providersIndex = loginProviders.reduce((acc, curr) => {
    acc[curr.providerId] = curr
    return acc
  }, {})

  async function addProvider(provider) {
    try {
      let notification = ''
      switch (provider) {
        case 'password':
          if (!validateEmail(newAccountEmail)) {
            throw {
              code: 'validation',
              message: 'Invalid email',
            }
          }
          if (newAccountPassword.length < 6) {
            throw {
              code: 'validation',
              message:
                newAccountPassword.length < 1
                  ? 'Password required'
                  : 'Password must be at least 6 characters long',
            }
          }

          setIsLoading(true)
          setErrorMessage('')

          await reauthenticateWithPopup(
            auth.currentUser,
            providersIndex['google.com']
              ? new GoogleAuthProvider()
              : new GithubAuthProvider(),
          )
          await linkWithCredential(
            auth.currentUser,
            EmailAuthProvider.credential(newAccountEmail, newAccountPassword),
          )

          await updateUserEmail(newAccountEmail)
          if (!auth.currentUser?.emailVerified) {
            sendVerificationLink(newAccountEmail)
          }
          setShowNewEmailLoginForm(false)
          setNewAccountEmail('')
          setNewAccountPassword('')
          notification = 'Email/Password login added'
          break
        case 'google.com':
          await linkWithPopup(auth.currentUser, new GoogleAuthProvider())
          notification = 'Google login added'
          break
        case 'github.com':
          await linkWithPopup(auth.currentUser, new GithubAuthProvider())
          notification = 'GitHub login added'
          break
      }

      setIsLoading(false)
      appState.addNotification({
        duration: 3,
        title: notification,
      })
    } catch (error) {
      console.log(error.code)
      setIsLoading(false)
      switch (error.code) {
        case 'validation':
          setErrorMessage(error.message)
          break
        case 'auth/popup-closed-by-user':
          setErrorMessage('Login popup closed by user')
          break
        case 'auth/user-mismatch':
          setErrorMessage('That login is not associated with this account')
          break
        case 'auth/email-already-in-use':
        case 'auth/credential-already-in-use':
          setErrorMessage('That email is associated with another account')
          break
        case 'auth/invalid-email':
          setErrorMessage('Invalid email address')
          break
        case 'auth/weak-password':
          setErrorMessage('Password must be at least 6 characters long')
          break
        case 'functions/internal':
          // This seems to happen without causing problems. @todo investigate
          break
        case 'auth/requires-recent-login':
        default:
          setErrorMessage(
            'Something went wrong. Email support@behold.so for help.',
          )
      }
    }
  }

  async function editEmail() {
    try {
      if (!validateEmail(newAccountEmail)) {
        throw {
          code: 'validation',
          message: 'Invalid email',
        }
      }
      if (currentPassword.length < 6) {
        throw {
          code: 'validation',
          message: 'Incorrect password',
        }
      }
      setErrorMessage('')
      setIsLoading(true)
      await reauthenticate(currentPassword)
      await updateEmail(auth.currentUser, newAccountEmail)
      await updateUserEmail(newAccountEmail)

      if (!auth.currentUser?.emailVerified) {
        sendVerificationLink(newAccountEmail)
      }
      setIsLoading(false)
      setShowEditEmailForm(false)
      appState.addNotification({
        duration: 3,
        title: 'Account email updated',
      })
    } catch (error) {
      console.log(error, error.code)
      setIsLoading(false)
      switch (error.code) {
        case 'validation':
          setErrorMessage(error.message)
          break
        case 'auth/wrong-password':
          setErrorMessage('Incorrect password')
          break
        case 'auth/invalid-email':
          setErrorMessage('Invalid email address')
          break
        case 'auth/email-already-in-use':
          setErrorMessage('That email is associated with another account')
          break
        case 'functions/internal':
          // This seems to happen without causing problems. @todo investigate
          break
        default:
          setErrorMessage(
            'Something went wrong. Email support@behold.so for help.',
          )
      }
    }
  }

  async function removeProvider(provider) {
    try {
      setIsLoading(true)
      if (Object.keys(providersIndex)?.length < 2) {
        throw {
          code: 'validation',
          message: 'You must have at least one login method',
        }
      }

      let notification = ''
      switch (provider) {
        case 'password':
          await reauthenticateWithPopup(
            auth.currentUser,
            providersIndex['google.com']
              ? new GoogleAuthProvider()
              : new GithubAuthProvider(),
          )
          await unlink(auth.currentUser, provider)

          const otherProviderEmails = loginProviders
            .filter((provider) => provider.providerId != 'password')
            .map((p) => p.email)

          // Primary email needs to change
          if (!otherProviderEmails.includes(auth.currentUser.email)) {
            await updateEmail(auth.currentUser, otherProviderEmails[0])
            await updateUserEmail(otherProviderEmails[0])
          }

          notification = 'Email/Password login removed'
          break
        case 'google.com':
          await unlink(auth.currentUser, provider)

          notification = 'Google login removed'
          break
        case 'github.com':
          await unlink(auth.currentUser, provider)

          notification = 'GitHub login removed'
          break
      }
      appState.addNotification({
        duration: 3,
        title: notification,
      })
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      switch (error.code) {
        case 'validation':
          setErrorMessage(error.message)
          break
        case 'auth/popup-closed-by-user':
          setErrorMessage('Login popup closed by user')
          break
        case 'auth/user-mismatch':
          setErrorMessage('That login is not associated with this account')
          break
        case 'functions/internal':
          // This seems to happen without causing problems. @todo investigate
          break
        case 'auth/requires-recent-login':
        default:
          setErrorMessage(
            'Something went wrong. Email support@behold.so for help.',
          )
      }
      setIsLoading(false)
    }
  }

  const newEmailLoginForm = (
    <div className={styles.new_email_login}>
      <div className={styles.note}>
        New emails will be sent a verification link unless they match an
        existing Google or Github login.
      </div>
      <FormItem label="New email" inputId="new-login-email">
        <ControlledInput
          id="new-login-email"
          value={newAccountEmail}
          type="email"
          onChange={setNewAccountEmail}
          disabled={isLoading}
          ref={focusOnMount}
        />
      </FormItem>
      <FormItem
        label="New password"
        note="6+ characters"
        inputId="new-login-password"
      >
        <ControlledInput
          id="new-login-password"
          value={newAccountPassword}
          type="password"
          onChange={setNewAccountPassword}
          disabled={isLoading}
          aria-description="must be 6 or more characters"
        />
      </FormItem>
      <div className={styles.new_email_login__footer}>
        <button
          className={styles.submit_button}
          disabled={isLoading}
          onClick={() => addProvider('password')}
        >
          Submit
        </button>
      </div>
    </div>
  )

  const editEmailForm = (
    <div className={styles.edit_email_login}>
      <div className={styles.note}>
        New emails will be sent a verification link unless they match an
        existing Google or Github login.
      </div>
      <FormItem label="New email" inputId="new-login-email">
        <ControlledInput
          id="new-login-email"
          value={newAccountEmail}
          type="email"
          onChange={setNewAccountEmail}
          disabled={isLoading}
          ref={focusOnMount}
        />
      </FormItem>
      <FormItem label="Current password" inputId="current-password">
        <ControlledInput
          id="current-password"
          value={currentPassword}
          type="password"
          onChange={setCurrentPassword}
          disabled={isLoading}
        />
      </FormItem>
      <div className={styles.new_email_login__footer}>
        <button
          className={styles.submit_button}
          disabled={isLoading}
          onClick={editEmail}
        >
          Submit
        </button>
      </div>
    </div>
  )

  const emailAuthEl = (
    <div
      className={classnames(styles.auth_type, {
        [styles.is_active]: providersIndex['password'],
      })}
    >
      <div className={styles.auth_type__inner}>
        <h2 className={styles.auth_type__title}>
          <EmailIcon />{' '}
          {!providersIndex['password'] && (
            <>
              <div className={styles.ellipsis_overflow}>Email & password</div>
              <span className={styles.label_inactive}>Not set up</span>
            </>
          )}
          {providersIndex['password'] && (
            <>
              <div className={styles.ellipsis_overflow}>
                {providersIndex['password']?.email}
              </div>
              <span className={styles.label}>Active</span>
            </>
          )}
        </h2>
        <div className={styles.auth_type__actions}>
          {!providersIndex['password'] && (
            <button
              className={styles.edit_button}
              aria-label="Add Email Login"
              disabled={isLoading}
              onClick={() => setShowNewEmailLoginForm(!showNewEmailLoginForm)}
              type="button"
            >
              {showNewEmailLoginForm ? <MinusIcon /> : <PlusIcon />}
            </button>
          )}
          {providersIndex['password'] && (
            <HelpTooltip
              showIcon={false}
              xAlign="center"
              containerId="body"
              delay={0}
              width={160}
              openOnClick={false}
              triggerText={
                <button
                  className={styles.edit_button}
                  aria-label="Change account email"
                  disabled={isLoading}
                  onClick={() => setShowEditEmailForm(!showEditEmailForm)}
                  type="button"
                >
                  <EditIcon />
                </button>
              }
            >
              <div className={styles.tooltip}>Change account email</div>
            </HelpTooltip>
          )}
          {providersIndex['password'] && loginProviders.length > 1 && (
            <HelpTooltip
              showIcon={false}
              xAlign="center"
              containerId="body"
              delay={0}
              width={150}
              openOnClick={false}
              triggerText={
                <button
                  className={styles.trash_button}
                  aria-label="Remove Email Login"
                  disabled={isLoading}
                  onClick={() => removeProvider('password')}
                >
                  <TrashIcon />
                </button>
              }
            >
              <div className={styles.tooltip}>Remove email login</div>
            </HelpTooltip>
          )}
        </div>
      </div>
      {showNewEmailLoginForm && newEmailLoginForm}
      {showEditEmailForm && editEmailForm}
    </div>
  )

  const googleAuthEl = (
    <div
      className={classnames(styles.auth_type, {
        [styles.is_active]: providersIndex['google.com'],
      })}
    >
      <div className={styles.auth_type__inner}>
        <h2 className={styles.auth_type__title}>
          <GoogleLogo />
          {!providersIndex['google.com'] && (
            <>
              Google
              <span className={styles.label_inactive}>Not set up</span>
            </>
          )}
          {providersIndex['google.com'] && (
            <>
              <div className={styles.ellipsis_overflow}>
                {providersIndex['google.com']?.email}
              </div>
              <span className={styles.label}>Active</span>
            </>
          )}
        </h2>
        <div className={styles.auth_type__actions}>
          {!providersIndex['google.com'] && (
            <HelpTooltip
              showIcon={false}
              xAlign="center"
              containerId="body"
              delay={0}
              width={150}
              openOnClick={false}
              triggerText={
                <button
                  className={styles.edit_button}
                  aria-label="Add Google Login"
                  disabled={isLoading}
                  onClick={() => addProvider('google.com')}
                >
                  <PlusIcon />
                </button>
              }
            >
              <div className={styles.tooltip}>Add Google login</div>
            </HelpTooltip>
          )}
          {providersIndex['google.com'] && loginProviders.length > 1 && (
            <HelpTooltip
              showIcon={false}
              xAlign="center"
              containerId="body"
              delay={0}
              width={160}
              openOnClick={false}
              triggerText={
                <button
                  className={styles.trash_button}
                  aria-label="Remove Google Login"
                  disabled={isLoading}
                  onClick={() => removeProvider('google.com')}
                >
                  <TrashIcon />
                </button>
              }
            >
              <div className={styles.tooltip}>Remove Google login</div>
            </HelpTooltip>
          )}
        </div>
      </div>
    </div>
  )

  const githubAuthEl = (
    <div
      className={classnames(styles.auth_type, {
        [styles.is_active]: providersIndex['github.com'],
      })}
    >
      <div className={styles.auth_type__inner}>
        <h2 className={styles.auth_type__title}>
          <GithubLogo />
          {!providersIndex['github.com'] && (
            <>
              GitHub
              <span className={styles.label_inactive}>Not set up</span>
            </>
          )}
          {providersIndex['github.com'] && (
            <>
              <div className={styles.ellipsis_overflow}>
                {providersIndex['github.com']?.email}
              </div>
              <span className={styles.label}>Active</span>
            </>
          )}
        </h2>
        <div className={styles.auth_type__actions}>
          {!providersIndex['github.com'] && (
            <HelpTooltip
              showIcon={false}
              xAlign="center"
              containerId="body"
              delay={0}
              width={150}
              openOnClick={false}
              triggerText={
                <button
                  className={styles.edit_button}
                  aria-label="Add GitHub Login"
                  disabled={isLoading}
                  onClick={() => addProvider('github.com')}
                >
                  <PlusIcon />
                </button>
              }
            >
              <div className={styles.tooltip}>Add GitHub login</div>
            </HelpTooltip>
          )}
          {providersIndex['github.com'] && loginProviders.length > 1 && (
            <HelpTooltip
              showIcon={false}
              xAlign="center"
              containerId="body"
              delay={0}
              width={170}
              openOnClick={false}
              triggerText={
                <button
                  className={styles.trash_button}
                  aria-label="Remove GitHub Login"
                  disabled={isLoading}
                  onClick={() => removeProvider('github.com')}
                >
                  <TrashIcon />
                </button>
              }
            >
              <div className={styles.tooltip}>Remove GitHub Login</div>
            </HelpTooltip>
          )}
        </div>
      </div>
    </div>
  )
  return (
    <Popup
      onRequestClose={onRequestClose}
      forceClose={forceClose}
      showCloseButton={false}
      closeFocusRef={closeFocusRef}
      closeOnMaskClick={!isLoading}
    >
      <div className={styles.container}>
        <header className={styles.header}>
          <h1 className={styles.title}>Manage login methods</h1>
        </header>
        <div className={styles.intro}>
          You may be asked to sign in again for security.
        </div>
        <Form
          className={styles.form}
          showArrow={false}
          showSubmitButton={false}
          isLoading={isLoading}
          errorMessage={errorMessage}
        >
          <div className={styles.auth_types}>
            {emailAuthEl}
            {googleAuthEl}
            {githubAuthEl}

            {isLoading && (
              <div className={styles.loading}>
                <l-squircle
                  stroke={3}
                  speed={0.65}
                  size={24}
                  color="var(--color-text-light)"
                ></l-squircle>
              </div>
            )}
          </div>
        </Form>
        <footer className={styles.footer}>
          <button
            onClick={() => setForceClose(true)}
            className={styles.close_button}
          >
            Done
          </button>
        </footer>
      </div>
    </Popup>
  )
}

ChangeBillingEmailPopup.propTypes = {
  onRequestClose: PropTypes.func,
  onSuccess: PropTypes.func,
  closeFocusRef: PropTypes.object,
}
