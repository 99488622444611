import clickNote from 'Helpers/clickNote'

export function getISOString(dateObj, resolution = 'day', only = false) {
  const year = dateObj.getUTCFullYear()
  const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0')
  const day = dateObj.getUTCDate().toString().padStart(2, '0')

  const monthString =
    resolution === 'day' || resolution === 'month' ? `-${month}` : ''
  const dayString = resolution === 'day' ? `-${day}` : ''

  switch (only) {
    case 'day':
      return day
    case 'month':
      return month
    case 'year':
      return year
  }

  return `${year}${monthString}${dayString}`
}

/*
 *   Forces sync/layout
 *   Useful when we need to guarantee that an element
 *   has been rendered before running css animations.
 *   Otherwise the element may render on the page
 *   with the end-state applied = no animation
 */
export function forceLayout() {
  return document.body.offsetTop
}

/*
 *   Clamp a number between two values
 */
export function clamp(input, min, max) {
  return Math.min(Math.max(min, input), max)
}

/*
 *   Split an array into n columns
 */
export function splitIntoColumns(arr, numColumns) {
  const columns = []

  for (let i = 0; i < numColumns; i++) {
    const column = []

    for (let j = i; j < arr.length; j += numColumns) {
      column.push(arr[j])
    }

    columns.push(column)
  }

  return columns
}

/*
 *   Simple email validation
 */
export function validateEmail(string) {
  return /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(string)
}

/*
 *   Simple password validation
 */
export function validatePassword(string) {
  return string.length > 0
}

/*
 *   Prune Properties from an object
 */
export function pruneObject(obj, keysToRemove) {
  const pruned = { ...obj }
  keysToRemove = Array.isArray(keysToRemove) ? keysToRemove : [keysToRemove]
  keysToRemove.forEach((k) => {
    delete pruned[k]
  })

  return pruned
}

/*
 *   Capitalize
 */
export function capitalize(str) {
  if (typeof str !== 'string') return str
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/*
 *   Copy text
 */
export function copyText(evt, text, options) {
  navigator.clipboard.writeText(text).then(
    function () {
      if (options?.noNote) return
      clickNote(options ? null : evt, 'Copied', {
        duration: 1000,
        forcePosition: 'top',
        ...options,
      })
    },
    function () {
      /* clipboard write failed */
    },
  )
}

/*
 *   Check if a variable strict equals any of an array of values
 */
export function isAnyOf(testVal, testArray) {
  return testArray.reduce((acc, curr) => curr === testVal || acc, false)
}

/*
 *   Check if two variables are the same
 */
export function isEqual(
  a,
  b,
  fuzzyNumbers = false,
  ignoreArrayOrder = false,
  propsToIgnore = [],
) {
  if (ignoreArrayOrder) {
    if (Array.isArray(a)) a = a.sort()
    if (Array.isArray(b)) b = b.sort()
  }
  if (fuzzyNumbers) {
    if (typeof a === 'number') a = a.toString()
    if (typeof b === 'number') b = b.toString()
  }

  if (typeof a !== typeof b) return false
  if (a === null && b === null) return true
  if (a === null && b !== null) return false
  if (a !== null && b === null) return false
  if (a === undefined && b === undefined) return true
  if (a === undefined && b !== undefined) return false
  if (a !== undefined && b === undefined) return false
  if (typeof a === 'object') {
    a = JSON.parse(JSON.stringify(a))
    b = JSON.parse(JSON.stringify(b))
    propsToIgnore.forEach((prop) => {
      delete a[prop]
      delete b[prop]
    })
    if (Object.keys(a).length !== Object.keys(b).length) return false

    return Object.entries(a).reduce((acc, [key, val]) => {
      return isEqual(val, b[key], fuzzyNumbers, ignoreArrayOrder) ? acc : false
    }, true)
  } else if (Array.isArray(a)) {
    let arrayIsEqual = true
    a.forEach((arrVal, index) => {
      if (!isEqual(arrVal, b[index], fuzzyNumbers, ignoreArrayOrder))
        arrayIsEqual = false
    })
    return arrayIsEqual
  } else {
    return a === b
  }
}

/*
 *   Is valid JSON
 */
export function isValidJson(json) {
  try {
    JSON.parse(json)
    return true
  } catch (e) {
    return false
  }
}

/*
 *   Abortable fetch
 */
export function abortableFetch(url) {
  let controller = new AbortController()
  const fetchPromise = fetch(url, {
    signal: controller.signal,
  }).catch((error) => {
    if (!controller.signal.aborted) {
      throw new Error(error.message)
    }
  })

  fetchPromise.cancel = () => {
    controller.abort('canceled')
  }

  return fetchPromise
}

/*
 *   Determine if feed should be refreshed
 */
export function shouldFeedRefresh(localSettings, remoteSettings) {
  const refreshProps = [
    'maxPosts',
    'hashtags',
    'hashtagFeedType',
    'hashtagFeedSort',
    'filter',
  ]

  let shouldRefresh = false

  refreshProps.forEach((prop) => {
    if (!isEqual(localSettings[prop], remoteSettings[prop], true)) {
      shouldRefresh = true
    }
  })

  if (
    localSettings['hashtags']?.length !== remoteSettings['hashtags']?.length
  ) {
    shouldRefresh = true
  }

  return shouldRefresh
}

/*
 *   Get window dimensions
 */
export function measureUniverse(el = 'window') {
  if (typeof window === 'undefined') {
    return {
      height: 0,
      docHeight: 0,
      width: 0,
      widthMinusScrollbars: 0,
      scrollX: 0,
      scrollY: 0,
    }
  }
  return {
    height: el === 'window' ? window.innerHeight : el.scrollHeight,
    docHeight: el === 'window' ? document.body.clientHeight : el.scrollHeight,
    width: window.innerWidth,
    widthMinusScrollbars: document.body.clientWidth,
    scrollX: el === 'window' ? window.scrollX : el.scrollLeft,
    scrollY: el === 'window' ? window.scrollY : el.scrollTop,
  }
}

/*
 *   Partially obscure an email address to protect it
 */
export function redactEmail(email) {
  const [address, domain] = email.split('@')
  const half = Math.floor(address.length / 2)
  const addressArray = address.split('').map((c, i) => {
    return i > half - 1 ? '*' : c
  })

  return addressArray.join('') + '@' + domain
}

/*
 *   Is an object literal
 */
export function isObject(variable) {
  return (
    typeof variable === 'object' &&
    variable !== null &&
    !Array.isArray(variable)
  )
}

/*
 *   Object or array exists and has contents
 */
export function isPopulated(objectOrArray, mustHaveContents = true) {
  if (mustHaveContents) {
    if (Array.isArray(objectOrArray)) {
      return !!objectOrArray && objectOrArray.length > 0
    } else if (isObject(objectOrArray)) {
      return !!objectOrArray && Object.keys(objectOrArray).length > 0
    } else {
      return false
    }
  } else {
    if (Array.isArray(objectOrArray)) {
      return !!objectOrArray && objectOrArray.length < 1
    } else if (isObject(objectOrArray)) {
      return !!objectOrArray && Object.keys(objectOrArray).length < 1
    } else {
      return false
    }
  }
}

/*
 *   Get feed poster
 */
export function getFeedPoster(feed) {
  let poster =
    feed?.filter((post) => !!post.sizes?.medium?.mediaUrl)[0] || feed?.[0]

  if (!poster) return null

  if (
    poster.mediaType === 'VIDEO' &&
    !poster.sizes?.medium?.mediaUrl &&
    !poster.thumbnailUrl
  ) {
    poster.mediaUrlType = 'VIDEO'
  }

  let backgroundColor = 'var(--color-algae)'
  if (poster?.colorPalette?.vibrantLight) {
    const rgbArray = poster?.colorPalette?.vibrantLight
      .split(',')
      .map((v) => parseInt(v))
    const lightened = rgbArray.map((v) => v + (255 - v) * 0.15).join(',')
    backgroundColor = `rgb(${lightened})`
  }

  if (poster) poster.backgroundColor = backgroundColor

  return poster
}

/*
 *   Hex to RGB
 */
export function hexToRGB(h) {
  let r = 0,
    g = 0,
    b = 0

  // 3 digits
  if (h.length == 4) {
    r = '0x' + h[1] + h[1]
    g = '0x' + h[2] + h[2]
    b = '0x' + h[3] + h[3]

    // 6 digits
  } else if (h.length == 7) {
    r = '0x' + h[1] + h[2]
    g = '0x' + h[3] + h[4]
    b = '0x' + h[5] + h[6]
  }

  return 'rgb(' + +r + ',' + +g + ',' + +b + ')'
}

/*
 *   Hex to HSL
 */
export function hexToHSL(H) {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0
  if (H.length == 4) {
    r = '0x' + H[1] + H[1]
    g = '0x' + H[2] + H[2]
    b = '0x' + H[3] + H[3]
  } else if (H.length == 7) {
    r = '0x' + H[1] + H[2]
    g = '0x' + H[3] + H[4]
    b = '0x' + H[5] + H[6]
  }
  // Then to HSL
  r /= 255
  g /= 255
  b /= 255
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0

  if (delta == 0) h = 0
  else if (cmax == r) h = ((g - b) / delta) % 6
  else if (cmax == g) h = (b - r) / delta + 2
  else h = (r - g) / delta + 4

  h = Math.round(h * 60)

  if (h < 0) h += 360

  l = (cmax + cmin) / 2
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  return h + ',' + s + ',' + l
}

/*
 *   HSL to Hex
 */
export function HSLToHex(hsl) {
  let [h, s, l] = hsl.split(',')
  s /= 100
  l /= 100

  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    r = 0,
    g = 0,
    b = 0

  if (0 <= h && h < 60) {
    r = c
    g = x
    b = 0
  } else if (60 <= h && h < 120) {
    r = x
    g = c
    b = 0
  } else if (120 <= h && h < 180) {
    r = 0
    g = c
    b = x
  } else if (180 <= h && h < 240) {
    r = 0
    g = x
    b = c
  } else if (240 <= h && h < 300) {
    r = x
    g = 0
    b = c
  } else if (300 <= h && h < 360) {
    r = c
    g = 0
    b = x
  }
  // Having obtained RGB, convert channels to hex
  r = Math.round((r + m) * 255).toString(16)
  g = Math.round((g + m) * 255).toString(16)
  b = Math.round((b + m) * 255).toString(16)

  // Prepend 0s, if necessary
  if (r.length == 1) r = '0' + r
  if (g.length == 1) g = '0' + g
  if (b.length == 1) b = '0' + b

  return '#' + r + g + b
}

/*
 *   JSON stringify with custom order for posts
 */
export function getSortedPostsJSON(posts, space = 0) {
  const keyOrder = [
    'id',
    'timestamp',
    'permalink',
    'mediaType',
    'isReel',
    'mediaUrl',
    'thumbnailUrl',
    'sizes',
    'height',
    'width',
    'small',
    'medium',
    'large',
    'full',
    'caption',
    'prunedCaption',
    'hashtags',
    'mentions',
    'colorPalette',
    'dominant',
    'muted',
    'mutedLight',
    'mutedDark',
    'vibrant',
    'vibrantLight',
    'vibrantDark',
    'children',
  ]

  const keys = new Set()

  // Get all keys, even nested
  JSON.stringify(posts, (key, value) => {
    keys.add(key)
    return value
  })

  // Return JSON with custom sorted keys
  return JSON.stringify(
    posts,
    Array.from(keys).sort((a, b) => {
      const aIndex = keyOrder.indexOf(a)
      const bIndex = keyOrder.indexOf(b)
      if (aIndex < 0 && bIndex < 0) return 0
      if (aIndex < 0) return 1
      if (bIndex < 0) return -1
      return aIndex - bIndex
    }),
    space,
  )
}

/*
 *   Validate a URL string
 */
export function isValidUrl(string, httpsOnly = false) {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  if (httpsOnly) {
    return url.protocol === 'https:'
  } else {
    return url.protocol === 'http:' || url.protocol === 'https:'
  }
}
