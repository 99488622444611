import { getFunctions, httpsCallable } from 'firebase/functions'
const functions = getFunctions()

/*
 *   Warm up updateStripe function
 */
export async function warmupSecretManager() {
  try {
    await httpsCallable(
      functions,
      'manageSecrets',
    )({
      warmup: true,
    })
  } catch (error) {
    console.log('failed to warmup function')
  }
}

export async function decipherSecret(secret) {
  return httpsCallable(
    functions,
    'manageSecrets',
  )({
    action: 'decipher',
    secret: secret,
  }).catch((error) => {
    console.log(error)
  })
}

export async function addApiSecret() {
  return httpsCallable(
    functions,
    'manageSecrets',
  )({
    type: 'api',
    action: 'create',
  }).catch((error) => {
    console.log(error)
  })
}

export async function deleteApiSecret(id) {
  return httpsCallable(
    functions,
    'manageSecrets',
  )({
    type: 'api',
    action: 'delete',
    secretId: id,
  }).catch((error) => {
    console.log(error)
  })
}

export async function addWebhookSecret() {
  return httpsCallable(
    functions,
    'manageSecrets',
  )({
    type: 'webhook',
    action: 'create',
  }).catch((error) => {
    console.log(error)
  })
}

export async function deleteWebhookSecret(id) {
  return httpsCallable(
    functions,
    'manageSecrets',
  )({
    type: 'webhook',
    action: 'delete',
    secretId: id,
  }).catch((error) => {
    console.log(error)
  })
}

export async function setActiveWebhookSecret(id) {
  return httpsCallable(
    functions,
    'manageSecrets',
  )({
    type: 'webhook',
    action: 'activate',
    secretId: id,
  }).catch((error) => {
    console.log(error)
  })
}
