import TransitionWrapper from 'Components/TransitionWrapper'
import Layout from 'Components/Layout'
import PageHeader from 'Components/PageHeader'
import LiveChatButton from 'Components/LiveChatButton'
import styles from './Help.module.scss'

export default function Help() {
  /*
   *   Render
   */
  return (
    <Layout metaTitle="Help | Behold">
      <main className={styles.container}>
        <PageHeader title="Help & Support" />

        <TransitionWrapper tag="section" className={styles.section}>
          <h2 className={styles.section_title}>Documention</h2>
          <div className={styles.docs}>
            <a
              href="https://behold.so/docs/getting-started"
              target="_blank"
              rel="noreferrer"
              className={styles.docs__link}
            >
              Getting started
            </a>
            <a
              href="https://behold.so/docs/widget"
              target="_blank"
              rel="noreferrer"
              className={styles.docs__link}
            >
              Widget docs
            </a>
            <a
              href="https://behold.so/docs/api"
              target="_blank"
              rel="noreferrer"
              className={styles.docs__link}
            >
              API docs
            </a>
            <a
              href="https://behold.so/docs/hashtag-feeds"
              target="_blank"
              rel="noreferrer"
              className={styles.docs__link}
            >
              Hashtag feeds
            </a>
            <a
              href="https://behold.so/docs#platforms"
              target="_blank"
              rel="noreferrer"
              className={styles.docs__link}
            >
              Platform guides
            </a>
          </div>
        </TransitionWrapper>
        <TransitionWrapper tag="section" className={styles.section}>
          <h2 className={styles.section_title}>Need support?</h2>
          <p className={styles.text}>
            Send us an email:{' '}
            <a href="mailto:support@behold.so">support@behold.so</a>
          </p>
        </TransitionWrapper>

        <TransitionWrapper tag="section" className={styles.section}>
          <h2 className={styles.section_title}>Live chat</h2>
          <LiveChatButton hideWhenOffline={false} />
        </TransitionWrapper>
      </main>
    </Layout>
  )
}
