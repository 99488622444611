import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { deleteAuthorizedAccount } from 'Fire/firebaseActions'
import useWindowSize from 'Hooks/useWindowSize'
import ReauthPopup from 'Components/ReauthPopup'
import PopupMenu from 'Components/PopupMenu'
import MenuItem from 'Components/MenuItem'
import HelpTooltip from 'Components/HelpTooltip'
import LazyImage from 'Components/LazyImage'
import TrashIcon from 'Images/icons/trash.svg'
import BasicSourceIcon from 'Images/icons/source-outline.svg'
import AdvancedSourceIcon from 'Images/icons/advanced.svg'
import RefreshIcon from 'Images/icons/refresh.svg'
import PlusIcon from 'Images/icons/plus.svg'
import Facebook from 'Images/icons/facebook.svg'
import styles from './Source.module.scss'

export default function Source({
  account: {
    accountType,
    name,
    proInstagramAccounts,
    instagramUserId,
    facebookUserId,
    username,
    reauthorize,
    reasonForReauth,
    expiresOn,
    profilePictureUrl,
  },
  first,
  last,
}) {
  const [showReauthPopup, setShowReauthPopup] = useState(false)
  const windowSize = useWindowSize()
  const title = accountType === 'FACEBOOK' ? name : <>@{username}</>
  const type =
    accountType === 'FACEBOOK' ? (
      <>
        <AdvancedSourceIcon className={styles.icon} />
        Advanced
      </>
    ) : (
      <>
        <BasicSourceIcon className={styles.icon} />
        Basic
      </>
    )
  const expiresDate = new Date(expiresOn.seconds * 1000).toLocaleDateString()
  const expires = accountType === 'FACEBOOK' ? expiresDate : 'Auto-renew'

  /*
   *   Menu items
   */
  const menuItemEls = useMemo(
    () => (
      <>
        <MenuItem
          to={`/add-feed/user/source/${
            facebookUserId ?? ''
          }_${instagramUserId}/type`}
        >
          <PlusIcon className={styles.menu_icon} /> New feed
        </MenuItem>
        {(accountType === 'FACEBOOK' || reauthorize) && (
          <MenuItem onClick={() => setShowReauthPopup(true)}>
            <RefreshIcon className={styles.menu_icon} /> Reauthorize
          </MenuItem>
        )}
        <MenuItem
          confirm
          confirmText={'Confirm Delete'}
          color="red"
          onClick={() =>
            deleteAuthorizedAccount(
              accountType === 'FACEBOOK' ? facebookUserId : instagramUserId,
            )
          }
        >
          <TrashIcon /> Remove source
        </MenuItem>
      </>
    ),
    [accountType, facebookUserId, instagramUserId],
  )

  let reauthMessage = (
    <p>
      This source is no longer connected. Reauthorize to start fetching fresh
      content again.
    </p>
  )

  switch (reasonForReauth) {
    case 'PASSWORD_CHANGE':
      reauthMessage = (
        <p>
          The password for this source was changed or the session was cleared
          for security reasons. Reauthorize to start fetching fresh content
          again.
        </p>
      )
      break

    case 'PRIVATE':
      reauthMessage = (
        <p>
          This Instagram account was set to private. Reauthorize to start
          fetching fresh content again.
        </p>
      )
      break
  }

  let proAccountEls =
    proInstagramAccounts && proInstagramAccounts !== 'LOADING' ? (
      Object.values(proInstagramAccounts)
        .filter((item) => !!item?.username)
        .sort((a, b) => {
          return a.username.localeCompare(b.username)
        })
        .map((account) => (
          <div className={styles.pro_account} key={account.instagramUserId}>
            {account.profilePictureUrl && (
              <LazyImage
                height={24}
                width={24}
                className={styles.profile_picture}
                src={account.profilePictureUrl}
              />
            )}
            <div className={classnames(styles.cell, styles.pro_username)}>
              @{account.username}
            </div>
          </div>
        ))
    ) : (
      <div className={styles.pro_account}>
        <div className={styles.cell}>
          <div className={styles.pro_account__loader}>
            <l-squircle
              speed={0.75}
              size={15}
              stroke={2}
              color="var(--color-text-light)"
            ></l-squircle>
          </div>
          Fetching associated Instagram accounts
        </div>
      </div>
    )

  if (
    proInstagramAccounts &&
    proInstagramAccounts !== 'LOADING' &&
    Object.keys(proInstagramAccounts).length === 0
  ) {
    proAccountEls = (
      <div className={styles.pro_account}>
        <div className={styles.cell}>
          No associated Instagram accounts.{' '}
          <a
            href="https://behold.so/docs/basic-vs-advanced-sources/#adding-an-advanced-source"
            target="_blank"
            rel="noreferrer"
          >
            Learn more.
          </a>
        </div>
      </div>
    )
  }

  const expiresEl =
    accountType === 'FACEBOOK' ? (
      <button
        className={styles.expires_date}
        title="Click to reauthorize"
        onClick={() => setShowReauthPopup(true)}
      >
        {expires}
      </button>
    ) : (
      expires
    )

  return (
    <>
      <div
        className={classnames(styles.container, {
          [styles.reauthorize]: reauthorize,
          [styles.first]: first,
          [styles.last]: last,
          [styles.advanced]: accountType !== 'FACEBOOK',
        })}
      >
        <div className={styles.inner}>
          <div className={classnames(styles.cell, styles.username)}>
            {profilePictureUrl && accountType !== 'FACEBOOK' && (
              <LazyImage
                height={24}
                width={24}
                className={styles.profile_picture}
                src={profilePictureUrl}
              />
            )}
            {!profilePictureUrl && accountType !== 'FACEBOOK' && (
              <div className={styles.profile_picture} />
            )}
            {accountType === 'FACEBOOK' && (
              <div className={styles.advanced_dot}>
                <Facebook />
              </div>
            )}
            <div className={styles.cell__inner}>{title}</div>
          </div>

          <div className={classnames(styles.cell, styles.type)}>{type}</div>

          <div className={classnames(styles.cell)}>
            {!reauthorize && expiresEl}
            {reauthorize && (
              <div className={classnames(styles.reauth_message)}>
                <HelpTooltip
                  width={290}
                  className={styles.reauth_text}
                  triggerText="Inactive"
                >
                  <div className={styles.tooltip}>
                    <h1>Reauthorization Required</h1>
                    {reauthMessage}
                  </div>
                </HelpTooltip>
                <button
                  className={styles.reauth_button}
                  onClick={() => setShowReauthPopup(true)}
                >
                  Reauthorize
                </button>
              </div>
            )}
          </div>

          <div className={classnames(styles.cell, styles.menu_wrapper)}>
            <PopupMenu
              className={styles.menu}
              containerId={windowSize.width > 600 ? 'content' : 'root'}
              width={180}
              offset={2}
              yAlign="bottom"
            >
              {menuItemEls}
            </PopupMenu>
          </div>
          {accountType === 'FACEBOOK' && (
            <div className={styles.pro_accounts}>{proAccountEls}</div>
          )}
        </div>
      </div>

      {showReauthPopup && (
        <ReauthPopup
          sourceId={instagramUserId || facebookUserId}
          onRequestClose={() => setShowReauthPopup(false)}
          redirectTo="/sources"
        />
      )}
    </>
  )
}

Source.propTypes = {
  account: PropTypes.object,
  first: PropTypes.bool,
  last: PropTypes.bool,
}
