import { useEffect, useState, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import {
  authorizeInstagramAccount,
  authorizeProInstagramAccount,
} from 'Helpers/instaApi'
import { useSlice } from 'State'
import Metas from 'Components/Metas'
import Logo from 'Images/logo.svg'
import CheckIcon from 'Images/icons/check.svg'
import styles from './AuthorizeSource.module.scss'

export default function AuthorizeSource() {
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const appState = useSlice('user', 'addNotification')
  const [state, setState] = useState('AUTHORIZING')
  const [errorMessage, setErrorMessage] = useState({
    title: 'Expired or incorrect code.',
    subtitle: null,
  })
  const navigate = useNavigate()

  /*
   *   Use Effect
   */
  useEffect(() => {
    const stateParam = searchParams.get('state')

    if (!stateParam) {
      setErrorMessage({ title: 'Missing code', subtitle: null })
      setState('ERROR')
    }

    const [accountId, accountType, reauth, redirectPath] = stateParam
      ? stateParam.replace('#_', '').split(',')
      : ''

    if (searchParams.has('code')) {
      if (accountType === 'business') {
        authorizeProInstagramAccount({
          code: searchParams.get('code'),
          account: accountId,
          accountType,
          isReauth: reauth === 'true',
        })
          .then(() => {
            // Logged in? Load Dashboard.
            if (appState.user.id) {
              if (redirectPath) {
                navigate(redirectPath, { replace: true })
              } else {
                navigate('/sources', { replace: true })
              }
              if (reauth === 'true') {
                appState.addNotification(
                  {
                    duration: 6,
                    text: 'Successfully reauthorized source',
                  },
                  'MAXPOSTS_UPDATED',
                )
              }

              // Otherwise display success message
            } else {
              setState('SUCCESS')
            }
          })
          .catch((error) => {
            console.log(error)
            setErrorMessage({
              title: 'Expired or incorrect code',
              subtitle: null,
            })
            setState('ERROR')
          })
      } else {
        authorizeInstagramAccount({
          code: searchParams.get('code'),
          account: accountId,
          accountType,
        })
          .then((res) => {
            if (res.data.status) {
              // Logged in? Load Dashboard.
              if (appState.user.id) {
                if (redirectPath) {
                  navigate(redirectPath, { replace: true })
                } else {
                  navigate('/sources', { replace: true })
                }

                // Otherwise display success message
              } else {
                setState('SUCCESS')
              }
            } else {
              setErrorMessage({
                title: 'Expired or incorrect code',
                subtitle: null,
              })
              setState('ERROR')
            }
          })
          .catch((error) => {
            const errorDetails = error.details
              ? JSON.parse(error.details)
              : error.message

            switch (true) {
              case errorDetails?.code == 10:
                setErrorMessage({
                  title: 'Required permissions missing',
                  subtitle: (
                    <>
                      <p>
                        Behold requires all requested permissions to function,
                        including those marked &quot;optional.&quot;{' '}
                      </p>
                      <p>
                        We take your privacy seriously, and only ask for access
                        to exactly the information we need to provide our
                        service. We will never share your data with a third
                        party for any reason.
                      </p>
                    </>
                  ),
                })
                break
              case errorDetails?.error_message?.includes(
                'Unsupported request - method type: get',
              ):
              case errorDetails?.message?.includes(
                'Unsupported request - method type: get',
              ):
                setErrorMessage({
                  title: 'Instagram API error',
                  subtitle: (
                    <>
                      <p>
                        Something went wrong on Instagram&apos;s side. This is
                        usually caused by a temporary outage in their service.
                        Please try again in a couple hours, and let us know
                        you&apos;re seeing this problem:{' '}
                        <a href="mailto:support@behold.so">support@behold.so</a>
                      </p>
                    </>
                  ),
                })
                break
              default:
                setErrorMessage({
                  title: 'Expired or incorrect code',
                  subtitle: null,
                })
            }

            requestAnimationFrame(() => {
              setState('ERROR')
            })
          })
      }
    }

    if (searchParams.has('error')) {
      const errorReasonParam = searchParams.get('error_reason')

      switch (errorReasonParam) {
        case 'user_denied':
          setErrorMessage({
            title: 'Access denied',
            subtitle:
              'Behold requires access to your Instagram account to function. Please try again and click "allow" to proceed.',
          })
          break
        default:
          setErrorMessage({
            title: 'Access denied',
            subtitle:
              'We were unable to connect to your Instagram account. Please try again, or contact support@behold.so for additional help.',
          })
      }

      requestAnimationFrame(() => {
        setState('ERROR')
      })
    }
  }, [appState.user.id, navigate, searchParams])

  /*
   *   Render
   */
  return (
    <main
      className={classnames(styles.container, {
        [styles.is_loading]: state === 'AUTHORIZING',
        [styles.is_successful]: state === 'SUCCESS',
        [styles.is_errored]: state === 'ERROR',
      })}
    >
      <Metas title="Authorizing | Behold" includeScripts={false} />
      <Logo className={styles.logo} />

      {/* Loading */}
      <div className={classnames(styles.loading, styles.overlay)}>
        <div className={classnames(styles.loading__title, styles.title)}>
          Authorizing
        </div>
        <div className={classnames(styles.loading__graphic, styles.graphic)}>
          <l-zoomies
            size={240}
            color="var(--color-text)"
            style={{ maxWidth: '100%' }}
          ></l-zoomies>
        </div>
      </div>

      {/* Success */}
      <div className={classnames(styles.success, styles.overlay)}>
        <div className={classnames(styles.success__icon, styles.graphic)}>
          <CheckIcon />
        </div>
        <div className={classnames(styles.success__title, styles.title)}>
          Successfully authorized
        </div>
        <div className={styles.success__description}>
          Feel free to close this tab.
        </div>
        <a href="https://behold.so" className={styles.success__button}>
          Go to homepage
        </a>
      </div>

      {/* Error */}
      <div className={classnames(styles.error, styles.overlay)}>
        <div className={classnames(styles.error__title, styles.title)}>
          {errorMessage.title}
        </div>
        {errorMessage.subtitle && (
          <div className={styles.error__subtitle}>{errorMessage.subtitle}</div>
        )}
        <div className={styles.error__footer}>
          {!appState.user.id && <a href="https://behold.so">Go to homepage</a>}
          {appState.user.id && (
            <a href="https://app.behold.so/add-source">Go back</a>
          )}
        </div>
      </div>
    </main>
  )
}
