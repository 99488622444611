import { useSlice } from 'State'

export default function useAuthUrl({ afterAuthRedirect = null, isReauth }) {
  const appState = useSlice('user')
  const basicID = process.env.BASIC_DISPLAY_CLIENT_ID
  const graphID = process.env.GRAPH_CLIENT_ID
  const redirectURI = `${process.env.BASE_URL}/authorize`
  const extras = isReauth
    ? `&extras={"setup":{"channel":"IG_API_ONBOARDING"}}`
    : ''

  const basicAuthUrl = `https://api.instagram.com/oauth/authorize?response_type=code&scope=user_profile,user_media&client_id=${basicID}&redirect_uri=${redirectURI}&state=${
    appState?.user?.id
  },personal,${isReauth}${afterAuthRedirect ? `,${afterAuthRedirect}` : ''}`

  const graphAuthUrl = `https://www.facebook.com/v18.0/dialog/oauth?response_type=code&display=page${extras}&scope=public_profile,instagram_basic,pages_read_engagement,pages_show_list,instagram_manage_insights,business_management&client_id=${graphID}&redirect_uri=${redirectURI}&state=${
    appState?.user?.id
  },business,${isReauth}${afterAuthRedirect ? `,${afterAuthRedirect}` : ''}`

  return { basicAuthUrl, graphAuthUrl }
}
