import { getFunctions, httpsCallable } from 'firebase/functions'
import { loadStripe } from '@stripe/stripe-js'
export const publishableKey = process.env.STRIPE_PUBLISHABLE_KEY

const functions = getFunctions()
export const initStripe = () => loadStripe(publishableKey)

/*
 *   Warm up updateStripe function
 */
export async function warmup() {
  try {
    await httpsCallable(
      functions,
      'updateStripe',
    )({
      action: 'WARMUP',
      payload: null,
    })
  } catch (error) {
    console.log('failed to warmup functions')
  }
}

/*
 *   Create a setup intent
 */
export function createSetupIntent({ customerId }) {
  return httpsCallable(
    functions,
    'updateStripe',
  )({
    action: 'CREATE_SETUP_INTENT',
    payload: { customerId },
  })
}

/*
 *   Update customer subscription
 */
export function updateCustomerPlan({
  paymentMethodId,
  priceId,
  planId,
  period,
  warmup,
}) {
  return httpsCallable(
    functions,
    'updateStripe',
  )({
    action: 'UPDATE_CUSTOMER_PLAN',
    payload: { paymentMethodId, priceId, planId, period, warmup },
  })
}

/*
 *   Update customer subscription
 */
export function updateCustomerEmail(customerId, email) {
  return httpsCallable(
    functions,
    'updateStripe',
  )({
    action: 'SET_CUSTOMER_BILLING_EMAIL',
    payload: { customerId, email },
  })
}

/*
 *   Set default payment method
 */
export function setDefaultPaymentMethod({ customerId, paymentMethodId }) {
  return httpsCallable(
    functions,
    'updateStripe',
  )({
    action: 'SET_DEFAULT_PAYMENT_METHOD',
    payload: { customerId, paymentMethodId },
  })
}

/*
 *  Retry invoice payment
 */
export function retryInvoicePayment({
  customerId,
  paymentMethodId,
  invoiceId,
}) {
  return httpsCallable(
    functions,
    'updateStripe',
  )({
    action: 'RETRY_INVOICE_PAYMENT',
    payload: { customerId, paymentMethodId, invoiceId },
  })
}

/*
 *   Cancel any subscription schedules
 */
export function cancelSubscriptionSchedule(subscriptionId) {
  return httpsCallable(
    functions,
    'updateStripe',
  )({
    action: 'CANCEL_SUBSCRIPTION_SCHEDULE',
    payload: subscriptionId,
  })
}

/*
 *   Update customer subscription
 */
export async function getProratedCharge({
  customerId,
  subscriptionId,
  newPriceId,
}) {
  if (!customerId || !subscriptionId || !newPriceId) {
    throw new Error('missing params')
  }

  return httpsCallable(
    functions,
    'updateStripe',
  )({
    action: 'GET_CHARGE_PREVIEW',
    payload: { customerId, subscriptionId, newPriceId },
  })
}
