import { getFunctions, httpsCallable } from 'firebase/functions'
const functions = getFunctions()

let previousSendTime = null

/*
 *   Create a setup intent
 */
export async function sendVerificationLink(email) {
  previousSendTime =
    sessionStorage.getItem('BEHOLD_LAST_VERIFICATION_EMAIL') || 0
  sessionStorage.setItem('BEHOLD_LAST_VERIFICATION_EMAIL', Date.now())

  return httpsCallable(
    functions,
    'sendVerificationLink',
  )({
    email,
  }).catch((error) => {
    console.log(error)
    sessionStorage.setItem('BEHOLD_LAST_VERIFICATION_EMAIL', previousSendTime)
  })
}
