import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import {
  authorizeInstagramAccount,
  authorizeProInstagramAccount,
} from 'Helpers/instaApi'
import useIsLoaded from 'Hooks/useIsLoaded'
import useTimers from 'Hooks/useTimers'
import useHotkey from 'Hooks/useHotkey'
import useAuthUrl from 'Hooks/useAuthUrl'
import Metas from 'Components/Metas'
import CopyText from 'Components/CopyText'
import DoodleArrow from 'Images/doodle-arrow-right.svg'
import CaretRightIcon from 'Images/icons/caret-right.svg'
import CaretLeftIcon from 'Images/icons/caret-left.svg'
import XIcon from 'Images/icons/x.svg'
import AdvancedIcon from 'Images/icons/advanced.svg'
import BasicIcon from 'Images/icons/source-outline.svg'
import styles from './AddSource.module.scss'

export default function AddSource({ sourceAPI = null }) {
  const location = useLocation()
  const navigate = useNavigate()
  const isLoaded = useIsLoaded()

  const afterAuthRedirect = location?.state?.afterAuthRedirect
    ? `${location?.state?.afterAuthRedirect}`
    : ''
  const { basicAuthUrl, graphAuthUrl } = useAuthUrl({
    type: 'basic',
    afterAuthRedirect: afterAuthRedirect,
    isReauth: false,
  })

  /*
   *   Go back one step
   */
  function goBack() {
    navigate(-1)
  }

  function handleKeyDown(evt) {
    if (evt.key === 'ArrowLeft') {
      goBack()
    }
  }

  useHotkey('Escape', () => navigate('/sources'))

  useEffect(() => {
    try {
      authorizeInstagramAccount({ warmup: true })
      authorizeProInstagramAccount({ warmup: true })
    } catch (error) {
      console.log('failed to warm up auth functions')
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [])

  return (
    <>
      <Metas title="Add a source | Behold" />
      <main className={styles.container}>
        <div
          className={classNames(styles.nav, {
            [styles.loaded]: isLoaded,
          })}
        >
          <button
            onClick={goBack}
            className={classNames(styles.nav__button, styles.back_button)}
          >
            <CaretLeftIcon /> Back{' '}
            <div className={styles.hotkey} aria-hidden>
              ◀
            </div>
          </button>
          <Link
            aria-label="Back to feeds"
            to="/"
            className={classNames(styles.nav__button, styles.close_button)}
          >
            <div className={styles.hotkey}>Esc</div>
            <XIcon />
          </Link>
        </div>
        {!sourceAPI && <ChooseAPI afterAuthRedirect={afterAuthRedirect} />}
        {sourceAPI === 'basic-instagram' && (
          <BasicAPI authLink={basicAuthUrl} />
        )}
        {sourceAPI === 'advanced-instagram' && (
          <AdvancedAPI authLink={graphAuthUrl} />
        )}
      </main>
    </>
  )
}

AddSource.propTypes = {
  sourceAPI: PropTypes.string,
}

/*
 *   Choose API
 */
function ChooseAPI({ afterAuthRedirect }) {
  const navigate = useNavigate()
  const [isLoaded, setIsLoaded] = useIsLoaded(true)
  const { st } = useTimers()

  function animove(url) {
    setIsLoaded(false)
    st(() => {
      navigate(url, { state: { afterAuthRedirect } })
    }, 200)
  }

  return (
    <>
      <h1 className={classNames(styles.title, { [styles.loaded]: isLoaded })}>
        Connect a source
      </h1>
      <div
        className={classNames(styles.options, { [styles.loaded]: isLoaded })}
      >
        <div className={styles.options_column}>
          <button
            onClick={() => {
              animove('/add-source/basic-instagram')
            }}
            id="skip-target"
            className={classNames(styles.option, styles.option__highlight)}
          >
            <div className={styles.option__inner}>
              <BasicIcon />
              Basic
              <CaretRightIcon className={styles.caret} />
            </div>
          </button>
          <div className={styles.option_footer}>
            <ul>
              <li>
                Easy to set up and use; <b>recommended for most cases</b>
              </li>
              <li>Automatic reauthorization</li>
            </ul>
          </div>
          <div className={styles.tip}>
            <DoodleArrow />
            <div className={styles.tip__text}>
              This is probably what you want
            </div>
          </div>
        </div>
        <div className={styles.options_column}>
          <button
            onClick={() => {
              animove('/add-source/advanced-instagram')
            }}
            className={styles.option}
          >
            <div className={styles.option__inner}>
              <AdvancedIcon />
              Advanced
              <CaretRightIcon className={styles.caret} />
            </div>
          </button>
          <div className={styles.option_footer}>
            <ul>
              <li>Enables hashtag feeds and access to collab posts</li>
              <li>Requires manual reauthorization every three months</li>
            </ul>
          </div>
        </div>
      </div>
      <div className={classNames(styles.footer, { [styles.loaded]: isLoaded })}>
        <a
          href="https://behold.so/docs/basic-vs-advanced-sources"
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </a>
      </div>
    </>
  )
}

/*
 *   Basic API
 */
function BasicAPI({ authLink }) {
  const isLoaded = useIsLoaded()

  return (
    <>
      <h1 className={classNames(styles.title, { [styles.loaded]: isLoaded })}>
        Connect a basic Instagram source
      </h1>
      <div className={classNames(styles.basic, { [styles.loaded]: isLoaded })}>
        <div
          className={classNames(styles.intro, { [styles.loaded]: isLoaded })}
        >
          Visit the link below while logged into Instagram.
        </div>
        <div className={styles.auth_link}>
          <CopyText className={styles.copy_text} value={authLink} />
          <a href={authLink} className={styles.button}>
            Open link <CaretRightIcon />
          </a>
        </div>

        <div className={classNames(styles.hint)}>
          <b>Tip:</b> You can send this link to other people. <br />
          They don&apos;t need to be logged-in to Behold to use it.
        </div>
      </div>
    </>
  )
}
BasicAPI.propTypes = {
  authLink: PropTypes.string,
}

/*
 *   Advanced API
 */
function AdvancedAPI({ authLink }) {
  const isLoaded = useIsLoaded()

  return (
    <>
      <h1 className={classNames(styles.title, { [styles.loaded]: isLoaded })}>
        Connect an advanced Instagram source
      </h1>
      <div
        className={classNames(styles.advanced, { [styles.loaded]: isLoaded })}
      >
        <div
          className={classNames(styles.intro, { [styles.loaded]: isLoaded })}
        >
          Visit the link below while logged into Facebook.
        </div>
        <div className={styles.auth_link}>
          <CopyText className={styles.copy_text} value={authLink} />
          <a href={authLink} className={styles.button}>
            Open link <CaretRightIcon />
          </a>
        </div>
        <div className={classNames(styles.hint)}>
          Advanced sources require a Facebook page
          <br /> connected to a professional Instagram account.{' '}
          <a
            href="https://behold.so/docs/basic-vs-advanced-sources/#adding-an-advanced-source"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
        </div>
      </div>
    </>
  )
}
BasicAPI.propTypes = {
  authLink: PropTypes.string,
}
