import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import FeedPanel from 'Components/feed/FeedPanel'
import HelpTooltip from 'Components/HelpTooltip'
import TransitionWrapper from 'Components/TransitionWrapper'
import ControlledSelect from 'Components/ControlledSelect'
import HashtagSearch from 'Components/HashtagSearch'
import PanelIcon from 'Images/icons/hash.svg'
import styles from 'Components/feed/FeedPanel.module.scss'

export default function FeedPanelHashtags({
  panelId,
  localFeedSettings,
  updateLocalFeedSetting,
  plan = {},
  hasChanges,
  saveChanges,
  resetChanges,
  feed,
}) {
  let maxHashtagsPerFeedText = (
    <p>
      On the <strong>{plan?.title}</strong> plan, you can mix up to{' '}
      {plan?.maxHashtagsPerFeed?.toLocaleString()} hashtags per feed.{' '}
      <Link to="/choose-plan">Upgrade</Link> to increase your limit.
    </p>
  )

  if (plan?.topTier) {
    maxHashtagsPerFeedText = (
      <p>
        On the <strong>{plan?.title}</strong> plan, you can mix up to{' '}
        {plan?.maxHashtagsPerFeed?.toLocaleString()} hashtags per feed.
      </p>
    )
  }

  return (
    <FeedPanel
      panelId={panelId}
      width={400}
      icon={<PanelIcon />}
      title="Hashtag settings"
      hasChanges={hasChanges}
      saveChanges={saveChanges}
      resetChanges={resetChanges}
    >
      <div className={styles.setting}>
        <div className={classNames(styles.label, styles.hashtags_label)}>
          Hashtags
        </div>
        <HashtagSearch
          instagramUserId={feed.instagramUserId}
          parentFbAccountId={feed.parentFacebookUserId}
          feedId={feed.id}
          hashtags={localFeedSettings.hashtags}
          onUpdate={(val) => updateLocalFeedSetting('hashtags', val)}
        />
      </div>

      {/* Hashtags Type */}
      <div className={classNames(styles.setting)}>
        <div className={styles.label}>
          <HelpTooltip width={270} triggerText="Hashtag feed type">
            <div className={styles.tooltip}>
              <h1>Hashtag Feed Type</h1>
              <p>
                <strong>Top Posts:</strong> Returns popular posts with this
                hashtag
              </p>
              <p>
                <strong>Recent Posts:</strong> Returns the most recent posts
                with this hashtag. Only returns posts added within the last 24
                hours.
              </p>
            </div>
          </HelpTooltip>
        </div>
        <ControlledSelect
          options={[
            { label: 'Top Posts', value: 'TOP' },
            { label: 'Recent Posts', value: 'RECENT' },
          ]}
          value={localFeedSettings.hashtagFeedType.toString()}
          onChange={(val) => updateLocalFeedSetting('hashtagFeedType', val)}
        />
      </div>

      {/* Hashtags Sort */}
      {localFeedSettings.hashtags.length > 1 && (
        <TransitionWrapper scale className={classNames(styles.setting)}>
          <div className={styles.label}>
            <HelpTooltip width={300} triggerText="Multi-hashtag sort">
              <div className={styles.tooltip}>
                <h1>Multi-hashtag Sort</h1>
                <p>
                  <strong>Mix:</strong> Feeds with multiple hashtags will return
                  an even mix of posts from each hashtag.
                </p>
                <p>
                  <strong>Timestamp:</strong> Feeds will return the most recent
                  posts first, regardless of hashtag. This might mean that you
                  sometimes only get posts from the most popular hashtag in your
                  feed.
                </p>
              </div>
            </HelpTooltip>
          </div>
          <ControlledSelect
            options={[
              { label: 'Mix', value: 'MIX' },
              { label: 'Timestamp', value: 'TIMESTAMP' },
            ]}
            value={localFeedSettings.hashtagFeedSort.toString()}
            onChange={(val) => updateLocalFeedSetting('hashtagFeedSort', val)}
          />
        </TransitionWrapper>
      )}
      <div
        className={classNames(styles.description, styles.description_narrow)}
      >
        {maxHashtagsPerFeedText}
      </div>
    </FeedPanel>
  )
}

FeedPanelHashtags.propTypes = {
  panelId: PropTypes.string,
  localFeedSettings: PropTypes.object,
  updateLocalFeedSetting: PropTypes.func,
  plan: PropTypes.object,
  hasChanges: PropTypes.bool,
  saveChanges: PropTypes.func,
  resetChanges: PropTypes.func,
  feed: PropTypes.object,
}
