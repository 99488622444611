import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { useSlice } from 'State'
import TransitionWrapper from 'Components/TransitionWrapper'
import Layout from 'Components/Layout'
import Source from 'Components/Source'
import PageHeader from 'Components/PageHeader'
import PlusIcon from 'Images/icons/plus.svg'
import ConnectIcon from 'Images/icons/connect.svg'
import styles from './Sources.module.scss'

export default function Sources() {
  const { user, authorizedAccounts, plans, feeds } = useSlice(
    'user',
    'authorizedAccounts',
    'plans',
    'feeds',
  )

  /*
   *   Active sources
   */
  const activeAccounts =
    authorizedAccounts &&
    Object.values(authorizedAccounts).filter((a) => a.active)

  /*
   *   Active Plan
   */
  const planId = user?.plan
  const activePlan = planId ? plans?.[planId] : null

  /*
   *   Allowed to activate more accounts?
   */
  const allowedToActivateAccounts =
    activePlan?.maxSources === -1 ||
    activeAccounts?.length < activePlan?.maxSources

  /*
   *   Sources
   */
  const authorizedAccountEls =
    authorizedAccounts && Object.values(authorizedAccounts).length
      ? Object.values(authorizedAccounts)
          .filter((item) => item?.username || item?.name)
          .sort((a, b) => {
            const aCompareVal = a.username || a.name
            const bCompareVal = b.username || b.name
            return aCompareVal.localeCompare(bCompareVal)
          })
          .map((a, i, array) => {
            return (
              <Source
                key={a.instagramUserId || a.facebookUserId}
                plan={activePlan}
                account={a}
                allowedToActivate={allowedToActivateAccounts}
                first={i === 0}
                last={i === array.length - 1}
              />
            )
          })
      : null

  const addAFeedMessage =
    authorizedAccounts &&
    Object.keys(authorizedAccounts).length &&
    (!feeds || !Object.keys(feeds).length) ? (
      <TransitionWrapper className={styles.no_feeds_message}>
        Nice! You&apos;ve connected a source. Now let&apos;s{' '}
        <Link to="/add-feed" className={styles.no_feeds_link}>
          Add a feed
        </Link>
      </TransitionWrapper>
    ) : null

  const accountsWrapperEl = feeds ? (
    <TransitionWrapper className={styles.sources}>
      {authorizedAccountEls}
    </TransitionWrapper>
  ) : (
    <TransitionWrapper className={styles.sources_loading}>
      <div className={styles.sources_loading__loader}></div>
    </TransitionWrapper>
  )

  /*
   *   Render
   */
  return (
    <Layout metaTitle="Sources | Behold">
      <main className={classnames(styles.container)}>
        <PageHeader
          title="Sources"
          buttonText={
            <>
              <PlusIcon /> Add source
            </>
          }
          buttonTo={'/add-source'}
        />
        {!!authorizedAccounts && !!Object.values(authorizedAccounts).length && (
          <TransitionWrapper className={styles.column_titles}>
            <div className={styles.column_title}>Name</div>
            <div className={classnames(styles.column_title, styles.type)}>
              Type
            </div>
            <div className={styles.column_title}>Expires</div>
          </TransitionWrapper>
        )}
        {accountsWrapperEl}
        {(!authorizedAccounts || !Object.values(authorizedAccounts).length) &&
          !!feeds && (
            <Link to="/add-source" className={styles.empty_message}>
              <div className={styles.empty_message__inner}>
                <ConnectIcon />
                <div>
                  Welcome! To get started let&apos;s <u>connect a source</u>
                </div>
              </div>
            </Link>
          )}
        {!!authorizedAccounts &&
          !!Object.values(authorizedAccounts).length &&
          !!feeds && (
            <TransitionWrapper>
              <Link to="/add-source" className={styles.add_source}>
                <div className={styles.add_source__inner}>
                  <PlusIcon /> Add source
                </div>
              </Link>
            </TransitionWrapper>
          )}

        {addAFeedMessage}
      </main>
    </Layout>
  )
}
