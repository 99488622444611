import { create } from 'zustand'
import { v1 as uuidv1 } from 'uuid'

const initialState = {
  appVersion: null,
  appIsReady: false,
  announcement: {
    isActive: false,
    title: '',
    text: '',
  },
  user: {
    id: null,
    stripeCustomerId: null,
    email: null,
    name: null,
    photo: null,
    plan: '',
    features: [],
  },
  paymentMethods: null,
  payments: null,
  authorizedAccounts: null,
  feeds: null,
  initialFeedsLoaded: false,
  newFeedIsLoading: false,
  addFeedIsLoading: false,
  plans: null,
  settings: {},
  notifications: new Map(),
}

export const useStore = create((set, get) => ({
  ...initialState,

  setAppVersion: (version) => set({ appVersion: version }),

  setAnnouncement: (announcement) => set({ announcement }),

  setAppIsReady: (status) => set({ appIsReady: status }),

  updateUser: (updates) => {
    const plans = get().plans
    const updatedUser = { ...get().user, ...updates }
    const features = [
      ...(plans?.[updatedUser.plan]?.features || []),
      ...updatedUser.features,
    ]
    set({ user: { ...updatedUser, features: Array.from(new Set(features)) } })
  },

  setAuthorizedAccounts: (authorizedAccounts) => set({ authorizedAccounts }),

  setFeeds: (feeds) => {
    const currentFeeds = get().feeds
    const updatedFeeds = currentFeeds
      ? Object.values(feeds)
          .map((feed) => {
            return {
              ...feed,
              usage: currentFeeds?.[feed.id]?.usage || 0,
              posts: currentFeeds?.[feed.id]?.posts || null,
            }
          })
          .reduce((acc, curr) => {
            acc[curr.id] = curr
            return acc
          }, {})
      : feeds

    set({ feeds: updatedFeeds })
  },

  setFeedIsUpdating: (feedId, status) => {
    const feeds = get().feeds
    const currentFeed = get().feeds[feedId]
    set({
      feeds: {
        ...feeds,
        [feedId]: {
          ...currentFeed,
          isUpdating: status,
        },
      },
    })
  },

  setFeedPosts: ({ feedId, posts }) => {
    const feeds = get().feeds
    const currentFeed = get().feeds[feedId]
    set({
      initialFeedsLoaded: true,
      feeds: {
        ...feeds,
        [feedId]: {
          ...currentFeed,
          posts,
        },
      },
    })
  },

  setFeedUsage: (usageData) => {
    const updatedFeeds = get().feeds
      ? Object.values(get().feeds)
          .map((feed) => {
            return { ...feed, usage: usageData[feed.id] }
          })
          .reduce((acc, curr) => {
            acc[curr.id] = curr
            return acc
          }, {})
      : {}

    set({ feeds: updatedFeeds })
  },

  setFeedTitle: (feedId, title) => {
    const feeds = get().feeds
    const currentFeed = get().feeds[feedId]
    set({
      feeds: {
        ...feeds,
        [feedId]: {
          ...currentFeed,
          title,
        },
      },
    })
  },

  updateFeedSettings: (feedId, updates) => {
    const feeds = get().feeds
    const currentFeed = get().feeds[feedId]
    set({
      feeds: {
        ...feeds,
        [feedId]: {
          ...currentFeed,
          settings: { ...currentFeed.settings, ...updates },
        },
      },
    })
  },

  setPlans: (plans) => {
    const currentUser = get().user
    const features = [
      ...(plans?.[currentUser.plan]?.features || []),
      ...currentUser.features,
    ]
    set({
      plans,
      user: {
        ...currentUser,
        features: Array.from(new Set(features)),
      },
    })
  },

  setNewFeedIsLoading: (status) => set({ newFeedIsLoading: status }),

  setAddFeedIsLoading: (status) => set({ addFeedIsLoading: status }),

  resetAppState: () => set(initialState),

  addNotification: (notification, notificationId) => {
    const id = notificationId || uuidv1()
    const notifications = new Map(get().notifications).set(id, {
      ...notification,
      id,
    })
    set({ notifications })
  },

  removeNotification: (notificationId) => {
    const copy = new Map(get().notifications)
    copy.delete(notificationId)
    set({ notifications: copy })
  },
}))

export function useSlice(...keys) {
  const stateObj = useStore((state) => {
    return Object.entries(state).reduce((acc, curr) => {
      return keys.includes(curr[0]) ? { ...acc, [curr[0]]: curr[1] } : acc
    }, {})
  })

  const handler = {
    get(target, prop) {
      if (Object.hasOwn && !Object.hasOwn(target, prop))
        throw new Error(
          `Requested property '${prop}', is not listed in the args of useSlice()`,
        )
      return target[prop]
    },
  }

  return new Proxy(stateObj, handler)
}
