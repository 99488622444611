import { useMemo, useEffect, useRef } from 'react'
import { Suspense, lazy } from 'preact/compat'
import { Routes, Route, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { useSlice } from 'State'
import useTimers from 'Hooks/useTimers'
import useFeatures from 'Hooks/useFeatures'
import { auth } from 'Fire/firebaseInit'
import { warmupManageImageUploads } from 'Helpers/manageUploads'
import { warmupSecretManager } from 'Helpers/manageSecrets'
import FirebaseConnect from 'Components/FirebaseConnect'
import Sidebar from 'Components/Sidebar'
import MobileHeader from 'Components/MobileHeader'
import AddFeedSourceType from 'Components/AddFeedSourceType'
import AddFeedSource from 'Components/AddFeedSource'
import AddFeedType from 'Components/AddFeedType'
import AddFeedName from 'Components/AddFeedName'
import AddFeedHashtags from 'Components/AddFeedHashtags'
import LoadingScreen from 'Components/LoadingScreen'
import PagePlaceholder from 'Components/PagePlaceholder'
import Bulletin from 'Components/Bulletin'
import Announcements from 'Components/Announcements'
import Notices from 'Components/Notices'
import SmartButton from 'Components/SmartButton'
import Auth from 'Pages/Auth'
import Feeds from 'Pages/Feeds'
import Feed from 'Pages/Feed'
import Sources from 'Pages/Sources'
import AddFeed from 'Pages/AddFeed'
import AddSource from 'Pages/AddSource'
import Help from 'Pages/Help'
import Account from 'Pages/Account'
import DeveloperSettings from 'Pages/DeveloperSettings'
import DeveloperSettingsNotSupported from 'Pages/DeveloperSettingsNotSupported'
import ChoosePlan from 'Pages/ChoosePlan'
import ConfirmPlan from 'Pages/ConfirmPlan'
import RetryPayment from 'Pages/RetryPayment'
import AuthorizeSource from 'Pages/AuthorizeSource'
import DeleteData from 'Pages/DeleteData'
import EmailActionHandler from 'Pages/EmailActionHandler'
import styles from './App.module.scss'

function lazyRetry(componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('behold-retry-lazy-refreshed') || 'false',
    )

    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('behold-retry-lazy-refreshed', 'false') // success so reset the refresh
        resolve(component)
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('behold-retry-lazy-refreshed', 'true') // we are now going to refresh
          return window.location.reload() // refresh the page
        }
        reject(error) // Default error behaviour as already tried refresh
      })
  })
}

function getPage(name) {
  return lazy(() => lazyRetry(() => import(`Pages/${name}.js`)))
}

function App() {
  const { appVersion, appIsReady, user, addNotification } = useSlice(
    'appVersion',
    'appIsReady',
    'user',
    'addNotification',
  )
  const features = useFeatures()
  const location = useLocation()
  const { st } = useTimers()
  const appVersionRef = useRef(appVersion)
  const FeedDemo = useMemo(() => getPage('FeedDemo'), [])

  const pagesWithoutSidebar = [
    'verify',
    'authorize',
    'deleteData',
    'add-feed',
    'add-source',
    'feeds/**',
    '/try',
  ]
  let showSidebar = true

  pagesWithoutSidebar.forEach((loc) => {
    const sub = loc.includes('/**')
    loc = loc.replace('/**', '')
    if (location.pathname.includes(loc)) {
      showSidebar = false

      if (sub) {
        showSidebar = !(location.pathname.length - loc.length > 2)
      }
    }
  })

  useEffect(() => {
    if (
      appVersion &&
      appVersionRef.current &&
      appVersion !== appVersionRef.current
    ) {
      sessionStorage.setItem('BEHOLD_IS_UPDATING', 'true')
      addNotification({
        title: 'New version available',
        text: (
          <>
            Please{' '}
            <button onClick={() => window.location.reload()}>
              refresh your browser
            </button>{' '}
            to update
          </>
        ),
      })
    }
    appVersionRef.current = appVersion
  }, [appVersion, addNotification])

  useEffect(() => {
    if (sessionStorage.getItem('BEHOLD_IS_UPDATING') === 'true') {
      st(() => {
        addNotification({
          duration: 10,
          title: 'Successfully updated',
          text: <>You&apos;re now using the latest and greatest</>,
        })
      }, 800)
    }
    sessionStorage.setItem('BEHOLD_IS_UPDATING', 'false')
  }, [st, addNotification])

  useEffect(() => {
    warmupSecretManager()
    warmupManageImageUploads()
  }, [])

  const showNotices =
    !location.pathname.includes('choose-plan') &&
    !location.pathname.includes('retry-payment') &&
    showSidebar

  return (
    <>
      <FirebaseConnect />
      {!appIsReady && !location.pathname.includes('auth') && <LoadingScreen />}

      {user.id && appIsReady && (
        <button
          onClick={() => document.getElementById('skip-target').focus()}
          className={styles.skip_link}
        >
          Skip to Content
        </button>
      )}

      {/* Logged-in and email verified */}
      {appIsReady && user.id && (
        <>
          <div className={styles.nav_container}>
            <Sidebar show={showSidebar} />
            <MobileHeader show={showSidebar} />
            <div
              className={classNames(styles.layout, {
                [styles.has_sidebar]: showSidebar,
              })}
              id="content"
            >
              {showSidebar ? <Announcements /> : null}
              <Notices show={showNotices} />

              <Suspense fallback={<PagePlaceholder />}>
                <Routes>
                  {/* Routes with Layout */}
                  <Route element={<Feeds />} path="*" />
                  <Route element={<Sources />} path="/sources" />
                  <Route element={<Account />} path="/account" />
                  <Route element={<Account />} path="/reset-password" />
                  <Route element={<ChoosePlan />} path="/choose-plan" />
                  <Route element={<ChoosePlan />} path="/choose-plan/:planId" />
                  <Route
                    element={<ConfirmPlan />}
                    path="/choose-plan/:planAndPeriod/confirm"
                  />
                  <Route
                    element={
                      features.has('api') ? (
                        <DeveloperSettings />
                      ) : (
                        <DeveloperSettingsNotSupported />
                      )
                    }
                    path="/developer-settings"
                  />
                  <Route element={<Help />} path="/help" />

                  {/* Routes without Layout */}
                  <Route element={<Feed />} path="/feeds/:feedId/*" />
                  <Route
                    element={
                      user.failedInvoice ? <RetryPayment /> : <Account />
                    }
                    path="/retry-payment"
                  />
                  <Route element={<EmailActionHandler />} path="/verify" />
                  <Route element={<AuthorizeSource />} path="/authorize" />
                  <Route element={<DeleteData />} path="/deleteData" />
                  {auth.currentUser.emailVerified ? (
                    <Route element={<AddFeed />} path="/add-feed">
                      <Route element={<AddFeedSourceType />} index />
                      <Route
                        element={<AddFeedSource />}
                        path=":sourceType/source"
                      />
                      <Route
                        element={<AddFeedType />}
                        path=":sourceType/source/:source/type"
                      />
                      <Route
                        element={<AddFeedName />}
                        path=":sourceType/source/:source/type/:type/name"
                      />
                      <Route
                        element={<AddFeedHashtags />}
                        path=":ids/hashtags"
                      />
                    </Route>
                  ) : (
                    <Route
                      element={
                        <div class={styles.unverified_message}>
                          <h1>
                            You must verify your email before adding new feeds
                          </h1>
                          <SmartButton color="grey" to="/">
                            Back to feeds
                          </SmartButton>
                        </div>
                      }
                      path="/add-feed/*"
                    ></Route>
                  )}
                  <Route element={<AddSource />} path="/add-source" />
                  <Route
                    element={<AddSource sourceAPI="basic-instagram" />}
                    path="/add-source/basic-instagram"
                  />
                  <Route
                    element={<AddSource sourceAPI="advanced-instagram" />}
                    path="/add-source/advanced-instagram"
                  />
                  <Route element={<FeedDemo />} path="/try/*" />
                </Routes>
              </Suspense>
            </div>
          </div>
        </>
      )}

      {/* Logged-out */}
      {appIsReady && !user.id && (
        <Suspense fallback={null}>
          <Routes>
            <Route element={<AuthorizeSource />} path="/authorize" />
            <Route element={<Auth form="sign-up" />} path="/register" />
            <Route
              element={<Auth form="sign-up" />}
              path="/choose-plan/:planAndPeriod/confirm"
            />
            <Route element={<Auth form="log-in" />} path="*" />
            <Route
              element={<Auth form="reset-password" />}
              path="/reset-password"
            />
            <Route element={<EmailActionHandler />} path="/verify" />
            <Route element={<DeleteData />} path="/deleteData" />
            <Route element={<FeedDemo />} path="/try/*" />
          </Routes>
        </Suspense>
      )}
    </>
  )
}

export default function Beholder() {
  return (
    <>
      <App />
      <Bulletin />
    </>
  )
}
