import { useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { deleteCurrentUser } from 'Fire/firebaseActions'
import useTimers from 'Hooks/useTimers'
import TransitionWrapper from 'Components/TransitionWrapper'
import Popup from 'Components/Popup'
import SmartButton from 'Components/SmartButton'
import styles from './DeleteCurrentUserPopup.module.scss'

export default function DeleteCurrentUserPopup({
  onRequestClose,
  closeFocusRef,
}) {
  const { st } = useTimers()
  const [isLoading, setIsLoading] = useState(false)
  const [forceClose, setForceClose] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  /*
   *   Submit
   */
  async function startDelete() {
    setIsLoading(true)

    try {
      const result = await deleteCurrentUser()
      if (result.data.status === 'error') throw new Error(result.data.error)

      setErrorMessage('Successfully deleted account.')

      st(() => {
        location.reload()
      }, 1500)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setErrorMessage(
        <>
          Account delete failed. Please contact{' '}
          <a href="mailto:support@behold.so">support@behold.so</a>
        </>
      )
    }
  }

  return (
    <Popup
      forceClose={forceClose}
      onRequestClose={onRequestClose}
      closeOnMaskClick={!isLoading}
      showCloseButton={false}
      closeFocusRef={closeFocusRef}
    >
      <div className={classnames(styles.container)}>
        <header className={styles.header}>
          <h1 className={styles.title}>Permanently delete account</h1>
        </header>
        <div className={styles.intro}>
          <p>
            All your feeds will stop functioning. If you have a paid plan it
            will be cancelled with no refund.{' '}
            <b>This action cannot be undone.</b>
          </p>
          <p>
            Is there something we can help with?{' '}
            <Link to="/help">Get support now</Link>
          </p>
        </div>
        <TransitionWrapper show={errorMessage} scale className={styles.error}>
          {errorMessage}
        </TransitionWrapper>
        <div className={styles.buttons}>
          <button
            className={styles.cancel_button}
            onClick={() => {
              if (isLoading) return
              setForceClose(true)
            }}
            disabled={isLoading}
          >
            Cancel
          </button>
          <SmartButton
            isLoading={isLoading}
            onClick={startDelete}
            color="light_terracotta"
            className={styles.delete__button}
            confirm
            confirmColor="red"
            confirmText={'Confirm delete'}
          >
            Delete account
          </SmartButton>
        </div>
      </div>
    </Popup>
  )
}

DeleteCurrentUserPopup.propTypes = {
  onRequestClose: PropTypes.func,
  closeFocusRef: PropTypes.object,
}
