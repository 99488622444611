import { useState, useEffect, useRef } from 'react'
import { isPopulated, capitalize } from 'Helpers/utils'
import { useSlice } from 'State'
import { retryInvoicePayment, updateCustomerPlan } from 'Helpers/stripe'
import { updateUser as updateUserInFirestore } from 'Fire/firebaseActions'
import useStripe from 'Hooks/useStripe'
import PageHeader from 'Components/PageHeader'
import Layout from 'Components/Layout'
import { Form, FormItem } from 'Components/Form'
import ControlledSelect from 'Components/ControlledSelect'
import AddNewCardPopup from 'Components/AddNewCardPopup'
import styles from './RetryPayment.module.scss'

export default function RetryPayment() {
  const appState = useSlice('user', 'updateUser', 'plans', 'addNotification')
  const {
    defaultPaymentMethod: currentPM,
    paymentMethods,
    stripeCustomerId,
    failedInvoice,
  } = appState.user
  const { stripe } = useStripe()
  const [selectedPM, setSelectedPM] = useState(currentPM)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [showNewCardPopup, setShowNewCardPopup] = useState(false)
  const closeFocusRef = useRef()

  function handleSuccess() {
    appState.addNotification({
      title: 'Payment successful',
      text: <>Your payment was processed successfully, thank you!</>,
      duration: 12,
    })
    updateUserInFirestore({ failedInvoice: false })
    appState.updateUser({ failedInvoice: false })
    setIsLoading(false)
  }

  async function handleError(result) {
    if (result.paymentIntent?.status === 'succeeded') {
      handleSuccess()
      return
    }

    updateUserInFirestore({ planIsUpdating: false })
    appState.updateUser({ planIsUpdating: false })

    switch (result.error) {
      case 'invoice_payment_intent_requires_action':
        {
          const paymentIntent = await stripe.confirmCardPayment(
            result.paymentIntent?.client_secret,
            {
              payment_method: selectedPM,
            },
          )
          if (paymentIntent.error) {
            setErrorMessage(
              'Failed to authenticate. Please try again with a different card.',
            )
          } else if (paymentIntent.status === 'succeeded') {
            handleSuccess()
          }
        }
        break
      case 'card_declined':
        {
          const message = result.paymentIntent?.last_payment_error?.message
          setErrorMessage(
            message || 'Card declined, please try again with a different card.',
          )
        }
        break
      case 'unknown':
        {
          setErrorMessage(
            'Something went wrong. Please try again or contact support@behold.so for assistance.',
          )
        }
        break
      case 'StripeInvalidRequestError':
        const status = result.paymentIntent?.status
        if (status === 'canceled') {
          if (result.paymentIntent?.cancellation_reason == 'void_invoice') {
            const priceObj = result.invoice?.lines?.data?.[0]?.price
            const priceId = priceObj?.id
            const planId = priceObj?.metadata?.planId
            const period = priceObj?.recurring?.interval

            try {
              updateUserInFirestore({ planIsUpdating: true })
              appState.updateUser({ planIsUpdating: true })

              const transactionResult = await updateCustomerPlan({
                paymentMethodId: selectedPM,
                priceId,
                planId,
                period,
              })

              const { latest_invoice } = transactionResult?.data || {}

              switch (latest_invoice?.payment_intent?.status) {
                case 'requires_action':
                  const paymentIntent = await stripe.confirmCardPayment(
                    latest_invoice?.payment_intent?.client_secret,
                    {
                      payment_method: selectedPM,
                    },
                  )
                  if (paymentIntent.error) {
                    handleError(paymentIntent.error)
                  } else if (paymentIntent.status === 'succeeded') {
                    handleSuccess()
                  }
                  break
                case 'requires_payment_method':
                  const message =
                    latest_invoice?.payment_intent?.last_payment_error?.message
                  setErrorMessage(
                    message ||
                      'Failed to authenticate. Please try again with a different card.',
                  )
                  break
                default:
                  handleSuccess()
              }
            } catch (error) {}
          }
          break
        }
      // falls through
      default: {
        const paymentIntent = await stripe.confirmCardPayment(
          result.paymentIntent?.client_secret,
          {
            payment_method: selectedPM,
          },
        )
        if (paymentIntent.error) {
          setErrorMessage(
            'Card declined, please try again with a different card.',
          )
        } else if (paymentIntent.status === 'succeeded') {
          handleSuccess()
        }
      }
    }

    setIsLoading(false)
  }

  /*
   *   Retry Payment
   */
  async function submit() {
    setErrorMessage(null)
    setIsLoading(true)
    updateUserInFirestore({ planIsUpdating: true })

    try {
      const result = await retryInvoicePayment({
        customerId: stripeCustomerId,
        paymentMethodId: selectedPM,
        invoiceId: failedInvoice,
      })

      if (result.data.status === 'error') {
        handleError(result.data)
      } else {
        handleSuccess()
      }
    } catch (error) {
      handleError({ error: 'unknown' })
    }
  }

  /*
   *   Handle new card popup close
   */
  function handleNewCardPopupClose() {
    setSelectedPM(currentPM)
    setShowNewCardPopup(false)
  }

  /*
   *   Open add new card popup when new option is selected
   */
  useEffect(() => {
    if (selectedPM === 'NEW' && !showNewCardPopup) {
      setShowNewCardPopup(true)
      setSelectedPM(currentPM)
    }
  }, [selectedPM, showNewCardPopup, currentPM])

  /*
   *   Card select optons
   */
  const cardOptions = isPopulated(paymentMethods)
    ? [...Object.values(paymentMethods), { id: 'NEW' }].map(
        ({ id, lastFour, expMonth, expYear, brand }) =>
          id === 'NEW'
            ? { value: id, label: 'Add new card' }
            : {
                label: `${capitalize(
                  brand,
                )} XXXX-${lastFour} — exp. ${expMonth}/${expYear
                  .toString()
                  .slice(2, 4)}`,
                value: id,
              },
      )
    : []

  /*
   *   Render
   */
  return (
    <Layout metaTitle="Retry Payment | Behold">
      <main className={styles.container}>
        <PageHeader title="Retry Payment" />
        <div className={styles.intro}>
          <p>
            Your last payment failed to process. Please try again or switch to a
            different card.
          </p>
          <p>
            If you need help, send an email to{' '}
            <a href="mailto:support@behold.so">support@behold.so</a> and
            we&apos;ll be happy to assist!
          </p>
        </div>
        <Form
          className={styles.form}
          isLoading={isLoading}
          errorMessage={errorMessage}
          onSubmit={submit}
          buttonAlignment="left"
          submitButtonContent={<>Retry payment</>}
        >
          {isPopulated(cardOptions) && (
            <FormItem>
              <ControlledSelect
                disabled={isLoading}
                options={cardOptions}
                value={selectedPM || ''}
                onChange={setSelectedPM}
                ref={closeFocusRef}
              />
            </FormItem>
          )}
        </Form>

        {showNewCardPopup && (
          <AddNewCardPopup
            stripeId={stripeCustomerId}
            onRequestClose={handleNewCardPopupClose}
            onOpen={() => setSelectedPM(currentPM)}
            closeFocusRef={closeFocusRef}
          />
        )}
      </main>
    </Layout>
  )
}
